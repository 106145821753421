
















































































































.login-form {
  max-width: 300px;
  margin: 136px auto 0;
  text-align: center;

  &__title {
    margin: 0 0 88px;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #4d4d4d;
  }

  &__login {
    width: 100%;
    margin-top: 32px;
  }
}
